import React, { useContext, useState } from 'react';
import { ProgressBar } from 'react-loader-spinner';
import Swal from 'sweetalert2';

import AndamentoIcon from './AndamentoIcon';
import API from '../../../services/requests';
import END from '../../../services/endpoints';

import BlobViewer from '../../common/BlobViewer';
import GlobalContext from '../../../context/GlobalContext';

import { IC, fileIcon, IconsUI, ui } from '../../../packages/wookie/icons';
import CustomDropdown from '../../../packages/wookie/misc/CustomDropdown';

export default function AndamentosLine({
  and,
  upd,
  c = 0,
  showDelete = true,
  allowBlobView = true,
}) {
  const { a } = useContext(GlobalContext);
  const { prioridades, status } = a;

  const [loading, setLoading] = useState(false);

  const handlePrioridadeChange = async (novaPrioridade) => {
    setLoading(true);
    try {
      await API(END.and.update.prioridade, {
        codigo: and.CODIGO,
        prioridade_id: novaPrioridade,
      });
      upd();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleStatusChange = async (novoStatus) => {
    setLoading(true);
    try {
      await API(END.and.update.status, {
        codigo: and.CODIGO,
        status: novoStatus,
      });
      upd();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    const delete_proc = async () => {
      setLoading(true);
      try {
        await API(END.processo_andamentos.delete, {
          codigo: and.CODIGO,
        });
        upd();
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você deseja deletar esse andamento? Essa ação não pode ser desfeita!',
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonColor: '#c41000',
      confirmButtonText: 'Sim, deletar',
      iconColor: '#c41000',
      denyButtonColor: '#6cbe00',
      denyButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Ok, deletado!', '', 'success');
        delete_proc();
      } else if (result.isDenied) {
        Swal.fire('Tudo bem, vou manter aqui', '', 'info');
      }
    });
  };

  const handleUpdateNotificar = async () => {
    setLoading(true);
    try {
      await API(END.processo_andamentos.update.notificar, {
        codigo: and.CODIGO,
        notificar_escritorio: !and.NOTIFICAR_ESCRITORIO,
      });
      upd();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleUpdateVisivel = async () => {
    setLoading(true);
    try {
      await API(END.processo_andamentos.update.visivel, {
        codigo: and.CODIGO,
        visivel_cliente: !and.VISIVEL_CLIENTE,
      });
      upd();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div
      key={`andamento_${and.ETAPA_ID}_${and.CODIGO}`}
      className='my-3 andamentoLine breakAvoid'
    >
      <div className='d-flex flex-column w100 breakAvoid'>
        <div className='gorow align-items-center justify-content-between'>
          <div className='gorow gocol550'>
            <div className='andamentoLineInfo '>
              <AndamentoIcon and={and.ANDAMENTO_ID} />
              <span className='fs10 me-2'>
                {new Date(and.UPDATED_AT).toLocaleString('pt-BR').split(',')[0]}
              </span>
              {showDelete ? (
                <CustomDropdown
                  arrayObj={prioridades}
                  value={and.PRIORIDADE_ID}
                  defaultValue={and.PRIORIDADE_ID}
                  changeCallApi={handlePrioridadeChange}
                  identification={and.ETAPA_ID}
                />
              ) : (
                <span className='fs09 textContrastLow me-2'>
                  (Prioridade{' '}
                  {prioridades.find((p) => p.ID === and.PRIORIDADE_ID).NOME})
                </span>
              )}
            </div>
            <div className='andamentoLineInfo '>
              {(and.ANDAMENTO_ID === 1 || and.ANDAMENTO_ID === 2) &&
              c === 0 ? (
                <>
                  <IconsUI
                    info={
                      and.NOTIFICAR_ESCRITORIO
                        ? ui.misc.bell.on
                        : ui.misc.bell.off
                    }
                    clsnm={`svg14 mx-2 ${
                      and.NOTIFICAR_ESCRITORIO ? 'cGreen' : 'cGray500'
                    }`}
                    tooltip={
                      and.NOTIFICAR_ESCRITORIO
                        ? 'Notificar Escritório'
                        : 'Não Notificar Escritório'
                    }
                    click={() => {
                      handleUpdateNotificar();
                    }}
                  />

                  <IconsUI
                    info={
                      and.VISIVEL_CLIENTE ? ui.misc.eye.on : ui.misc.eye.off
                    }
                    clsnm={`svg14 mx-2 ${
                      and.VISIVEL_CLIENTE ? 'cGreen' : 'cGray500'
                    }`}
                    tooltip={
                      and.VISIVEL_CLIENTE
                        ? 'Visível ao Cliente'
                        : 'Não Visível ao Cliente'
                    }
                    click={() => {
                      handleUpdateVisivel();
                    }}
                  />
                </>
              ) : (
                ''
              )}

              {and.ANDAMENTO_ID === 3 && c === 0 ? (
                showDelete ? (
                  <CustomDropdown
                    arrayObj={status}
                    defaultValue={and.STATUS_ID}
                    value={and.STATUS_ID}
                    changeCallApi={handleStatusChange}
                    identification={and.ETAPA_ID}
                  />
                ) : (
                  <span className='fs09 textContrastLow me-2 '>
                    (Situação: {status.find((s) => s.ID === and.STATUS_ID).NOME}
                    )
                  </span>
                )
              ) : (
                ''
              )}
              <span className=' fs07 textContrastLow '>
                por {and.CREATED_BY_NOME}
              </span>
            </div>
          </div>

          <div>
            {loading ? (
              <ProgressBar
                visible={true}
                height='40'
                width='130'
                barColor='#C41000'
                borderColor='#6B2C2B'
                ariaLabel='progress-bar-loading'
                wrapperStyle={{}}
                wrapperClass=''
              />
            ) : c ? (
              ''
            ) : (
              showDelete && (
                <IconsUI
                  info={ui.action.delete.trash}
                  clsnm='svg14 cLink me-2 mousePointerHover'
                  click={() => handleDelete()}
                  id={`and_tt_del_${and.ETAPA_ID}`}
                  tooltip='Remover Andamento'
                />
              )
            )}
          </div>
        </div>
        <div className='d-flex flex-row align-items-center'>
          <div className='d-flex flex-column w100'>
            {and.TITLE !== null ? (
              <span>
                <span className='fs10 fw600 mb-0 textContrast'>
                  {and.TITLE}
                </span>
                {and.PRAZO !== null ? (
                  <span className='fs09 ms-2'>
                    {'prazo: '}
                    {new Date(and.PRAZO).toLocaleString('pt-BR').split(',')[0]}
                  </span>
                ) : (
                  ''
                )}
              </span>
            ) : (
              ''
            )}
            {and.ANDAMENTO !== null ? (
              <span className='fs10 mb-1 prewrap'>{and.ANDAMENTO}</span>
            ) : (
              ''
            )}
            {and.NOTA !== null && and.NOTA !== '' ? (
              <>
                <hr className='m-0 mt-1 wp150 textContrastLow' />
                <div className='fs09 textContrastLow d-flex flex-row align-items-baseline'>
                  <span className='me-2 h10'>
                    <IC.FaLock />
                  </span>
                  <span className='fs10 mb-1 prewrap'>{and.NOTA}</span>
                </div>
              </>
            ) : (
              ''
            )}
            {and.FILES_TYPE ? (
              <div className='col-12'>
                <BlobViewer
                  blobIcon={fileIcon(and.FILES_TYPE, 'svg14')}
                  blobCode={and.CODIGO}
                  blobType={and.FILES_TYPE}
                  blobName={and.DOCUMENTO_TYPE}
                  blobTitle={and.FILES_NAME}
                  allowBlobView={allowBlobView}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
