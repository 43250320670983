import React, { useContext, useEffect, useState } from 'react';
import {
  Public,
  NavBar,
  Client,
  GlobalContext,
  RenderSection,
  RenderSectionLine,
  PKG,
} from '../config/imports';
import '../assets/css/app/render.css';

export default function Dashboard() {
  const { checkLogin, store, showSection } = useContext(GlobalContext);
  const { theme } = useContext(PKG.PackageContext);
  // const rolesArray = store().app_roles;
  const [logged, setLogged] = useState(false);

  // const [view, setView] = useState('');
  // const [currView, setCurrView] = useState('');

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pc } = store();

  const selectDashboard = (selectedRole) => {
    switch (selectedRole) {
      case '1C9DBBA19500E1B7E063845B000AE1AE':
        return <Client />;
      default:
        return (
          <>
            <RenderSectionLine
              role={
                pc === '1C9DBBA194FAE1B7E063845B000AE1AE' ? selectedRole : null
              }
            />
            <RenderSection section={showSection} />
          </>
        );
    }
  };

  if (logged) {
    return (
      <div data-bs-theme={theme}>
        <NavBar />
        {/* {pc === '1C9DBBA194FAE1B7E063845B000AE1AE' ? (
          <div
            className={`w100 d-flex bgGray800 flex-row justify-content-center align-items-center fs05 py-2`}
          >
            <span className='cYellow me-3 fw600'>{currView}</span>
            {rolesArray.map((role) => (
              <button
                key={role.ID}
                className='btn btn-sm btn-outline-light mx-2 fs08'
                onClick={() => {
                  setView(role.CODIGO);
                  setCurrView(role.DESCRIPTION);
                  setShowSection(role.SECTION);

                }}
                size='sm'
              >
                {role.ID}
              </button>
            ))}

            <button
              className='btn btn-sm btn-outline-warning mx-2 fs08'
              onClick={() => {
                setView('');
                setCurrView('');
              }}
              size='sm'
            >
              clear
            </button>
          </div>
        ) : null} */}

        <div className='d-flex flex-column container py-5'>
          {/* {view ? selectDashboard(view) : selectDashboard(pc)} */}
          {selectDashboard(showSection)}
        </div>
      </div>
    );
  }

  return (
    <>
      <Public />
    </>
  );
}
