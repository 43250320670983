import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PKG, API, END, GlobalContext } from '../config/imports';

export default function GlobalProvider({ children }) {
  const navigate = useNavigate();
  const pkgContext = useContext(PKG.AuthContext);
  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState('news');
  const [a, setA] = useState([]);
  const [emailArchive, setEmailArchive] = useState();
  const [emailccArchive, setEmailccArchive] = useState();
  const [redirectPath, setRedirectPath] = useState('/');
  const [isAuthenticated, setIsAuthenticated] = useState(checkLogin());
  // const [messages, setMessages] = useState([]);
  const [sounds, setSounds] = useState(sessionStorage.getItem('@App:psn') || 0);

  function store() {
    return {
      token: sessionStorage.getItem('@App:token'),
      email: sessionStorage.getItem('@App:email'),
      user: sessionStorage.getItem('@App:user'),
      codigo: sessionStorage.getItem('@App:c'),
      role: sessionStorage.getItem('@App:role'),
      role_id: sessionStorage.getItem('@App:i'),
      tenant: sessionStorage.getItem('@App:tc'),
      phone: sessionStorage.getItem('@App:telefone'),
      role_description: sessionStorage.getItem('@App:description'),
      ref: sessionStorage.getItem('@App:r'),
      sec: sessionStorage.getItem('@App:s'),
      dis: sessionStorage.getItem('@App:d'),
      pc: sessionStorage.getItem('@App:p'),
      apoio: JSON.parse(sessionStorage.getItem('@App:apoio')),
      app_roles: JSON.parse(sessionStorage.getItem('@App:app_roles')),
    };
  }

  useEffect(() => {
    const { apoio } = store();
    if (a.length === 0 && apoio !== null) {
      setLoading(true);
      setup(apoio);
      setLoading(false);
    }
  }, [a]);

  function checkLogin() {
    const { token, email, user } = store();
    if (!token || !email || !user) {
      return false;
    }
    return true;
  }

  // async function starterMessages(token) {
  //   try {
  //     const response = await API(END.views.notifications, {
  //       Authorization: token,
  //       token,
  //     });
  //     const data = response.data;
  //     setMessages((prevMessages) => [...prevMessages, ...data]);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const removeAspas = (str) => str.replace(/['"]+/g, '');

  async function login(userData) {
    const response = await pkgContext.login(END.auth.login, userData, [
      'i',
      'c',
      'tc',
      'p',
      'token',
      'role',
      'description',
      'r',
      'd',
      's',
      'user',
      'email',
      'telefone',
    ]);
    const { success } = response.data;

    if (success) {
      setIsAuthenticated(true);
      const { token } = store();
      await getApoio(token);
      const user_roles = await API(END.users_roles.read, { token });
      sessionStorage.setItem(
        '@App:roles',
        removeAspas(JSON.stringify(user_roles.data)),
      );

      const app_roles = await API(END.roles.read, { token });
      const editedRoles = app_roles.data
        ? app_roles.data.map((item) => {
            const disallowArray = removeAspas(item.DISALLOW).split(',');
            if (disallowArray[0] === '') {
              return { ...item, DISALLOW: [] };
            }
            return { ...item, DISALLOW: disallowArray };
          })
        : [];
      sessionStorage.setItem('@App:app_roles', JSON.stringify(editedRoles));
      sessionStorage.setItem('@App:psn', 0);
    }
    return response;
  }

  async function userData() {
    const response = await API(END.auth.user, {});
    return response.data;
  }

  async function logout(full = 0) {
    setLoading(true);
    await pkgContext.logout(END.auth.logout, full);
    setIsAuthenticated(false);
    setLoading(false);
  }

  function setup(data) {
    const ap = {
      faixas: data.filter((info) => info.TABELA === 'faixas'),
      variacoes: data.filter((info) => info.TABELA === 'variacoes'),
      bancos: data.filter((info) => info.TABELA === 'bancos'),
      operacoes: data.filter((info) => info.TABELA === 'operacoes'),
      prioridades: data.filter((info) => info.TABELA === 'prioridades'),
      andamentos: data.filter((info) => info.TABELA === 'andamentos'),
      status: data.filter((info) => info.TABELA === 'status'),
      status_simulacoes: data.filter(
        (info) => info.TABELA === 'status_simulacoes',
      ),
      documentos: data.filter((info) => info.TABELA === 'documentos'),
      tabelas: data.filter((info) => info.TABELA === 'tabelas'),
      imobiliarias: data.filter((info) => info.TABELA === 'imobiliarias'),
      corretores: data.filter((info) => info.TABELA === 'corretores'),
      correspondentes: data.filter((info) => info.TABELA === 'correspondentes'),
      analises: data.filter((info) => info.TABELA === 'analises'),
      etapas: data
        .filter((info) => info.TABELA === 'etapas')
        .map((etapa) => {
          return {
            ID: etapa.ID,
            ETAPA: etapa.NOME,
            SEQUENCIA: etapa.VALUE,
            ESTIMATIVA: etapa.VAR_VALUE,
          };
        }),
      support_categories: data.filter(
        (info) => info.TABELA === 'support_categories',
      ),
      statusSim: {},
    };

    const statusSim = {};
    ap.status_simulacoes.forEach((status) => {
      statusSim[status.ID] = {
        id: status.ID,
        name: status.NOME,
        color: status.COLOR,
      };
    });

    ap.statusSim = statusSim;

    setA(ap);
  }

  async function getApoio(token) {
    const response = await API(END.views.apoio, { token });
    const data = await response.data;

    setup(data);
    sessionStorage.setItem('@App:apoio', JSON.stringify(data));
  }

  const context = {
    loading,
    setLoading,
    API,
    checkLogin,
    getApoio,
    navigate,
    showSection,
    setShowSection,
    login,
    logout,
    a,
    store,
    userData,
    emailArchive,
    setEmailArchive,
    emailccArchive,
    setEmailccArchive,
    redirectPath,
    setRedirectPath,
    isAuthenticated,
    setIsAuthenticated,
    sounds, setSounds,
  };

  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
}
