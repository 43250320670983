import AuthContext from './AuthContext';
import { API } from '..';

export default function AuthProvider({ children }) {
  const removeAspas = (str) => str.replace(/['"]+/g, '');

  function createStore(data, paramsToStore = []) {
    const st = {};
    paramsToStore.forEach((param) => {
      st[param] = data[param];
    });
    Object.keys(st).forEach((key) => {
      sessionStorage.setItem(
        `@App:${key}`,
        removeAspas(JSON.stringify(st[key])),
      );
    });
  }

  async function login(endpoint, userData = {}, paramsToStore = []) {
    const response = await API(endpoint, userData);
    const { success } = response.data;
    if (success) {
      createStore(response.data, paramsToStore);
    }
    return response;
  }

  async function logout(endpoint, full = 0) {
    const token = sessionStorage.getItem('@App:token');
    sessionStorage.clear();
    localStorage.clear();
    await API(endpoint, {
      token,
      full,
    });
  }

  const context = {
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}
