import Swal from 'sweetalert2';

export function Toast({ icon = 'success', title = '', timer = 3000, position = 'center' }) {
  const Toast = Swal.mixin({
    toast: true,
    position,
    iconColor: 'white',
    width: '300px',
    customClass: {
      popup: 'colored-toast',
    },
    showConfirmButton: false,
    timer,
    timerProgressBar: true,
  })

  Toast.fire({
    icon,
    title,
  });
}