import React, { useContext, useEffect, useState } from 'react';
import { Form, ProgressBar, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import '../assets/css/app/page.processo.css';

import {
  ProtocolosTable,
  AndamentoModal,
  AndamentosLine,
  EtapaIcon,
  Footer,
  GlobalContext,
  PKG,
  CONF,
  SCR,
  NavBar,
  API,
  END,
  BlobViewer,
} from '../config/imports';

export default function Processo() {
  const {
    checkLogin,
    navigate,
    store,
    setEmailArchive,
    setEmailccArchive,
    loading,
  } = useContext(GlobalContext);
  const { theme } = useContext(PKG.PackageContext);

  const { numberFormat } = SCR.Helpers;
  const { codigo } = useParams();

  const { apoio } = store();
  const etapas = apoio
    .filter((info) => info.TABELA === 'etapas')
    .map((etapa) => {
      return {
        ID: etapa.ID,
        ETAPA: etapa.NOME,
        SEQUENCIA: etapa.VALUE,
        ESTIMATIVA: etapa.VAR_VALUE,
      };
    });

  const [processo, setProcesso] = useState();
  const [steps, setSteps] = useState(etapas);
  const [andamentos, setAndamentos] = useState([]);
  const [protocolos, setProtocolos] = useState([]);

  const [processoLoading, setProcessoLoading] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [percent, setPercent] = useState(0);
  const [percentColor, setPercentColor] = useState('primary');

  const [andamentoModal, setAndamentoModal] = useState([]);
  // const [concluidosModal, setConcluidosModal] = useState([]);

  const [procdocs, setProcdocs] = useState([]);

  // const [refreshMe, setRefreshMe] = useState(false);

  const [showEtapa, setShowEtapa] = useState([]);

  const [sendingEmail, setSendingEmail] = useState(false);
  const [infoEmail, setInfoEmail] = useState('');

  const [logged, setLogged] = useState(true);

  const handleToggleModal = (index, modal, prevStates) => {
    let newStates = [];
    switch (modal) {
      case 'modal':
        newStates = [...prevStates];
        newStates[index] = !newStates[index];
        setAndamentoModal(newStates);
        break;

      default:
        break;
    }
  };

  async function loadBlobContent(blobCode, blobType) {
    const { token } = store();
    try {
      const form = new FormData();
      form.append('codigo', blobCode);
      form.append('files_type', blobType);
      form.append('token', token);

      const url = `${CONF.ORA}${END.processo_andamentos.read.docs}/`;
      const response = await fetch(url, {
        method: 'POST',
        body: form,
      });

      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error(error);
    }
  }

  const sendDocsToEmail = async (
    email = null,
    archive = false,
    inform = false,
    emailcc = null,
  ) => {
    setSendingEmail(true);
    inform && setInfoEmail('Enviando email...');
    const emailTo = email || document.getElementById('enviarEMail').value;

    const setDocs = async () => {
      const documentsToSend = [];
      const filesToSend = [];
      const filesNameToSend = [];

      document.querySelectorAll('.checkDocumentProcesso').forEach((check) => {
        if (archive) {
          documentsToSend.push(check.id);
        } else {
          if (check.checked) documentsToSend.push(check.id);
        }
      });

      const blobs = async () => {
        const blobsPromises = [];
        documentsToSend.map((doc) => {
          const file = procdocs.find((d) => d.CODIGO === doc);
          blobsPromises.push(
            loadBlobContent(
              file.ORIGIN === 'simulacao',
              file.CODIGO,
              file.FILES_TYPE,
              file.SIMULACAO_CODIGO,
            ),
          );
          return null;
        });

        const res = await Promise.all(blobsPromises);
        return res;
      };

      const blobsResponse = await blobs();

      blobsResponse.map((blob, index) => {
        const file = procdocs.find((d) => d.CODIGO === documentsToSend[index]);
        // const blobURL = URL.createObjectURL(blob);
        filesToSend.push(blob);
        filesNameToSend.push(file.FILES_NAME);
        return null;
      });

      const emailContent = () => {
        const lis = documentsToSend.map((doc) => {
          const name = procdocs.find((d) => d.CODIGO === doc).FILES_NAME;
          const type = procdocs.find((d) => d.CODIGO === doc).DOCUMENTO_TYPE;
          return `<li>${name}  |  (${type})</li>`;
        });

        return `         
          <div>            
              <p>Seguem em anexo os documentos do processo de ${
                processo.CLIENTE_NOME
              }</p>
              <p style='padding-top: 2em;font-weight: 600;'>
                Código: ${processo.PROCESSO_CODIGO}
              </p>
              <p>Cliente: ${processo.CLIENTE_NOME}</p>
              <p>Telefone: ${processo.CLIENTE_TELEFONE}</p>
              <p>E-mail: ${processo.CLIENTE_EMAIL}</p>
              <p>Operação: ${processo.OPERACAO}</p>
              <p>Valor: ${numberFormat(processo.VALOR_IMOVEL)}</p>
              <p>Cidade: ${processo.CIDADE_IMOVEL}</p>
              <p>Corretor: ${
                processo.CORRETOR_NOME === null
                  ? 'Nenhum'
                  : processo.CORRETOR_NOME
              }</p>
              <p>Imobiliária: ${
                processo.IMOBILIARIA_NOME === null
                  ? 'Nenhuma'
                  : processo.IMOBILIARIA_NOME
              }</p>
              <p style='padding-top: 2em;font-weight: 600;'>Documentos anexados:</p>
              <ul>
                ${lis.join('')}
              </ul>
              <br><br><br>
              <p>Atenciosamente,</p>
              <p>Equipe ${CONF.tenant}</p>
          </div>
        `;
      };

      return { filesToSend, filesNameToSend, emailContent };
    };

    const infos = await setDocs();

    try {
      const logo = await API(END.tenants.logo_link, {
        brand: CONF.brand,
      });
      const logo_link = logo.data;
      const { filesToSend, filesNameToSend, emailContent } = infos;
      const formData = new FormData();
      formData.append('email', emailTo);
      formData.append(
        'subject',
        `Documentos | ${processo.CLIENTE_NOME} [${processo.PROCESSO_CODIGO}]`,
      );
      // formData.append('files', filesToSend);
      // formData.append('filename', filesNameToSend);
      filesToSend.forEach((file, index) => {
        formData.append('files[]', file); // 'files[]' indicates an array
        formData.append('filename[]', filesNameToSend[index]); // 'filename[]' as an array for each filename
      });
      if (emailcc) formData.append('cc', emailcc);
      formData.append('content', emailContent());
      formData.append('logo_link', logo_link);
      formData.append('brand', CONF.brand);

      await API(END.bwAPI.maildocs, formData, true);
    } catch (error) {
      console.error(error);
    } finally {
      inform && setInfoEmail(`E-mail enviado com sucesso para ${emailTo}!`);
      setSendingEmail(false);
    }
  };

  const handleArchive = async () => {
    setEmailArchive(CONF.backupmail);
    if (CONF.backupmailcc) setEmailccArchive(CONF.backupmailcc);
    setProcessoLoading(true);
    try {
      if (CONF.backupmailcc) {
        await sendDocsToEmail(CONF.backupmail, true, false, CONF.backupmailcc);
      } else {
        await sendDocsToEmail(CONF.backupmail, true, false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setProcessoLoading(false);
    }
    navigate(`/report/processo/${processo.PROCESSO_CODIGO}`);
  };

  async function get_processo() {
    setProcessoLoading(true);
    setEmailArchive('');
    setEmailccArchive('');
    try {
      const [processoResponse, andamentosResponse, protocolosResponse] =
        await Promise.all([
          API(END.processos.read.codigo, { codigo }),
          API(END.processo_andamentos.read.all, { codigo }),
          API(END.views.protocolos_processo, { codigo }),
        ]);

      const { data: processoData } = processoResponse;
      const { data: andamentosData } = andamentosResponse;
      const { data: protocolosData } = protocolosResponse;

      if (processoData) setProcesso(processoData[0]);
      const perc = processoData
        ? ((processoData[0].ETAPA_ATUAL_ID * 100) / etapas.length).toFixed(0)
        : 0;
      setPercent(perc);

      if (perc < 20) {
        setPercentColor('secondary');
      } else if (perc < 40) {
        setPercentColor('warning');
      } else if (perc < 60) {
        setPercentColor('info');
      } else if (perc < 80) {
        setPercentColor('primary');
      } else {
        setPercentColor('success');
      }

      setAndamentoModal(etapas.map(() => false));
      setAndamentos(andamentosData);

      const docs = andamentosData.filter((doc) => doc.ANDAMENTO_ID === 2);
      setProcdocs(docs);

      if (protocolosData) setProtocolos(protocolosData);
    } catch (error) {
      console.error(error);
      setProcessoLoading(false);
    }
    setProcessoLoading(false);
  }

  const handleUpdate = () => {
    const upd = updated;
    setUpdated(!upd);
  };

  useEffect(() => {
    const etapasAndamentos = steps.map((etapa) => {
      const ands = andamentos && andamentos.filter((and) => and.ETAPA_ID === etapa.ID);
      if (ands.length > 0) {
        etapa.andamentos = ands.sort((a, b) => {
          return new Date(a.CREATED_AT) - new Date(b.CREATED_AT);
        });
        etapa.INICIO = ands[0].CREATED_AT;
        etapa.FIM = ands[ands.length - 1].CREATED_AT;
        etapa.ATUAL = processo.ETAPA_ATUAL_ID === etapa.ID;
        // console.log(processo);
        // console.log(processo.ETAPA_ATUAL_ID);
        etapa.CONCLUIDA = processo.ETAPA_ATUAL_ID > etapa.ID;
      } else {
        etapa.andamentos = [];
        etapa.INICIO = '';
        etapa.ATUAL = false;
        etapa.CONCLUIDA = false;
      }

      return etapa;
    });
    setSteps(etapasAndamentos);

    setShowEtapa(
      etapasAndamentos.map((etapa) => {
        return etapa.ATUAL;
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [andamentos]);

  useEffect(() => {
    const status = checkLogin();
    if (!status) {
      navigate('/logout');
    }
    setLogged(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getproc = async () => {
      await get_processo();
    };
    getproc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated]);

  const headerProcesso = () => {
    return (
      <>
        <div className='processo-header my-3'>
          <div className='d-flex flex-row align-items-center justify-content-between textContrastColor '>
            <h4 className='ffSoft mb-3'>Processo</h4>

            <div className='gorow align-items-center justify-content-end'>
              <PKG.IconsUI
                info={PKG.ui.action.update}
                clsnm='svg14 hovGreen'
                click={handleUpdate}
                tooltip='Atualizar lista de simulações'
              />
              {!processo.C && (
                <PKG.IconsUI
                  info={PKG.ui.file.pdf}
                  clsnm='svg14 cRed hovGreen mousePointerHover ms-2'
                  canvasIgnore={true}
                  tooltip='Imprimir PDF'
                  id='btnPrintPDF'
                  click={() =>
                    navigate(`/report/processo/${processo.PROCESSO_CODIGO}`)
                  }
                />
              )}
              {store().role_id < 4 && (
                <PKG.IconsUI
                  info={PKG.ui.archive.in}
                  clsnm='svg14 cRed hovGreen mousePointerHover ms-2'
                  canvasIgnore={true}
                  tooltip='Arquivar processo'
                  id='btnArchive'
                  click={handleArchive}
                />
              )}
            </div>
          </div>
          <h6 className='textContrastColor'>{processo.NOME}</h6>
          <div>
            <p>{`${processo.IMOVEL_DESCRICAO}: ${numberFormat(
              processo.IMOVEL_VALOR,
            )} (${processo.IMOVEL_CIDADE})`}</p>
            <p>
              {`Corretor: ${
                processo.CORRETOR_NOME === null
                  ? 'Nenhum'
                  : processo.CORRETOR_NOME
              }`}
              {processo.IMOBILIARIA_NOME !== null &&
              processo.IMOBILIARIA_NOME !== '' &&
              processo.IMOBILIARIA_NOME !== 'Nenhuma'
                ? ` | Imobiliária: ${processo.IMOBILIARIA_NOME}`
                : ''}
            </p>
          </div>
          <ProgressBar
            now={percent}
            variant={percentColor}
            label={`${percent}%`}
            className='bgContrastSoft'
          />
        </div>
        {!processo.C ? (
          <>
            <div className='d-flex flex-row justify-content-between mt-4 mb-1'>
              <h6 className='ffSoft cBlue'>Protocolos do Processo</h6>
              <PKG.IconsUI
                info={PKG.ui.expand.more}
                clsnm='hovSvgSoft hovGreen cLink svg16 mousePointerHover'
                click={() => {
                  const el = document.getElementById('dv_cont_processo');
                  el.classList.toggle('divHide');
                }}
              />
            </div>
            <div
              id='dv_cont_processo'
              className={`mb-4 ${protocolos.length === 0 ? 'divHide' : ''}`}
            >
              <ProtocolosTable view_protocolos={protocolos} />
            </div>
          </>
        ) : (
          ''
        )}
        <>
          <div className='d-flex flex-row justify-content-between mt-4 mb-1'>
            <h6 className='ffSoft cBlue'>Documentos</h6>
            <PKG.IconsUI
              info={PKG.ui.expand.more}
              clsnm='hovSvgSoft hovGreen cLink svg16 mousePointerHover'
              click={() => {
                const el = document.getElementById('dv_docs_processo');
                el.classList.toggle('divHide');
              }}
            />
          </div>
          <div
            id='dv_docs_processo'
            className={`mb-4 ${procdocs.length === 0 ? 'divHide' : ''}`}
          >
            {procdocs.map((doc) => {
              return (
                <div
                  key={doc.CODIGO}
                  className='d-flex flex-row justify-content-start align-items-center py-2'
                  id={`doc_${doc.CODIGO}`}
                >
                  <input
                    className='form-check m-0 me-2 checkDocumentProcesso'
                    data-bs-theme={theme}
                    type='checkbox'
                    id={doc.CODIGO}
                    key={`select_${doc.CODIGO}`}
                  />
                  <BlobViewer
                    key={doc.CODIGO}
                    blobCode={doc.CODIGO}
                    blobType={doc.FILES_TYPE}
                    blobIcon={PKG.fileIcon(doc.FILES_TYPE, 'svg14')}
                    blobName={doc.FILES_NAME}
                    docSim={doc.ORIGIN === 'simulacao'}
                    cod_doc_sim={doc.SIMULACAO_CODIGO}
                    blobTitle={doc.DOCUMENTO_TYPE}
                  />
                </div>
              );
            })}

            {sendingEmail ? (
              <PKG.LoadingDots />
            ) : (
              <div className='gorow gocol650 align-items-center py-2'>
                <Button
                  className='btn-sm btn-secondary fs10 fw300'
                  type='button'
                  onClick={() => {
                    const checksArr = document.querySelectorAll(
                      '.checkDocumentProcesso',
                    );
                    checksArr.forEach((check) => {
                      check.checked = !check.checked;
                    });
                  }}
                >
                  Selecionar/Remover Todos
                </Button>

                <Form.Group className='m-2 gorow align-items-center justify-content-start'>
                  <Form.Label
                    className='m-0 fs12 wp90'
                    htmlFor='enviarEMail'
                  >
                    Enviar para
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='enviarEMail'
                    size='sm'
                    name='enviarEMail'
                    className='m-0  form-control wp300'
                  />
                  <Button
                    className='btn btn-sm btn-primary cWhite fs12 ms-1'
                    type='button'
                    onClick={sendDocsToEmail}
                  >
                    Enviar
                  </Button>
                </Form.Group>
              </div>
            )}
            <p>{infoEmail}</p>
          </div>
        </>

        <h6 className='my-4 ffSoft cBlue'>Etapas</h6>
      </>
    );
  };

  const bodyProcesso = () => {
    if (!processo || !steps) {
      return <PKG.LoadingDots conf={CONF} />;
    }
    return (
      <PKG.ContainerBox
        main={
          <div className='container w100 mb-5'>
            {headerProcesso()}
            {steps.map((et, index) => {
              return (
                <div
                  key={et.SEQUENCIA}
                  className={`divEtapa ${
                    et.CONCLUIDA
                      ? 'etConcluida'
                      : et.ATUAL
                      ? 'etAtual'
                      : 'etPendente'
                  }`}
                >
                  <div className='etapaHeader'>
                    <div className='etapaHeaderStart py-1 gorow gocol550'>
                      <span className='headerSection ffSoft'>
                        <span className='me-2'>
                          <EtapaIcon etapa={et.ETAPA} />
                        </span>
                        <span
                          className={`me-3 fs12 ffSoft ${
                            et.CONCLUIDA
                              ? 'textContrastLow'
                              : et.ATUAL
                              ? 'fw700 fs16 cBlue'
                              : ''
                          }`}
                        >
                          {et.ETAPA}
                        </span>
                        {et.CONCLUIDA ? (
                          <PKG.IconsUI
                            info={PKG.ui.misc.check.circle}
                            clsnm='cGreen me-2'
                          />
                        ) : (
                          ''
                        )}
                      </span>

                      <span className='headerSection fs10'>
                        <span className='mx-1'>
                          <PKG.IconsUI
                            info={PKG.ui.action.start}
                            clsnm={`svg14 ${
                              et.CONCLUIDA
                                ? ' cGreen'
                                : et.ATUAL
                                ? ' cBlue'
                                : ' textContrastLow'
                            }`}
                          />
                        </span>
                        <span className='mx-1'>
                          {et.CONCLUIDA || et.ATUAL
                            ? new Date(et.INICIO).toLocaleDateString('pt-BR')
                            : ''}
                        </span>
                        <span className='mx-1'>
                          {et.CONCLUIDA ? (
                            <>
                              <PKG.IconsUI
                                info={PKG.ui.status.done}
                                clsnm={`svg14  cGreen`}
                              />
                              <span className='mx-1'>
                                {new Date(et.FIM).toLocaleDateString('pt-BR')}
                              </span>
                            </>
                          ) : (
                            <>
                              <PKG.IconsUI
                                info={PKG.ui.status.waiting}
                                clsnm={`svg14 cBlue mx-2`}
                              />
                              <span className='mx-1'>
                                {et.ESTIMATIVA === 1
                                  ? 'Hoje'
                                  : `+ ${et.ESTIMATIVA} dias`}
                              </span>
                            </>
                          )}
                        </span>
                      </span>
                    </div>
                    {et.ATUAL ? (
                      <span className='fs10 fw600 ffSoft textContrastColor px-2'>
                        Etapa Atual
                      </span>
                    ) : (
                      <PKG.IconsUI
                        info={PKG.ui.expand.more}
                        clsnm='hovSvgSoft hovGreen cLink svg16 mousePointerHover'
                        click={() => {
                          const state = showEtapa[index];
                          console.log(state);
                          setShowEtapa(() => {
                            const newStates = [...showEtapa];
                            newStates[index] = !state;
                            return newStates;
                          });
                          localStorage.setItem(
                            `${processo.PROCESSO_CODIGO}_${index}`,
                            !state,
                          );
                        }}
                      />
                    )}
                  </div>
                  <div
                    id={`ands_${et.ID}`}
                    className={`etapaContainer ${
                      et.ATUAL ? 'divAtual' : 'divHide'
                    } ${
                      localStorage.getItem(`show_${index}`) === true ||
                      showEtapa[index]
                        ? 'showMe'
                        : ''
                    }`}
                  >
                    <div className={`ffSoft fs10  rowConcluido }`}>
                      <PKG.IconsUI
                        info={PKG.ui.action.add.reg}
                        clsnm='svg14 mousePointerHover hovGreen cLink'
                        tooltip='Adicionar Andamento'
                        sideDivClass='d-flex flex-row justify-content-end mousePointerHover'
                        sideText='Adicionar Andamento'
                        sideTextClass='me-2 fs10 cLink'
                        sideTextPosition='left'
                        click={() =>
                          handleToggleModal(index, 'modal', andamentoModal)
                        }
                      />

                      <AndamentoModal
                        key={`modal_andamento_${et.ID}`}
                        show={andamentoModal[index]}
                        proc_etapa_id={et.ID}
                        procs={processo}
                        updated={setUpdated}
                        setShow={() =>
                          handleToggleModal(index, 'modal', andamentoModal)
                        }
                      />
                    </div>

                    {et.andamentos &&
                      et.andamentos.map((and) => {
                        return (
                          <AndamentosLine
                            key={and.CODIGO}
                            and={and}
                            upd={handleUpdate}
                            c={processo.C}
                            procs={processo}
                            handleModal={() =>
                              handleToggleModal(index, 'modal', andamentoModal)
                            }
                          />
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        }
        footer={<Footer />}
        navbar={<NavBar />}
        overlayMain={false}
        classMain='container'
        navreturn={true}
        navreturnClass='container'
      />
    );
  };

  if (!logged) {
    navigate('/logout');
  } else {
    if (processoLoading || loading) {
      return <PKG.LoadingDots conf={CONF} />;
    }

    return bodyProcesso();
  }
}
