import React, { useContext, useEffect, useState } from 'react';
import { IC } from '../../packages/wookie/icons';
import '../../assets/css/app/noty.css';
import API from '../../services/requests';
import END from '../../services/endpoints';
import Dropdown from 'react-bootstrap/Dropdown';
import GlobalContext from '../../context/GlobalContext';
import { PackageContext } from '../../packages/wookie';
import { Toast } from '../../assets/js/Alerts';

export default function Noty() {
  const { navigate, sounds } = useContext(GlobalContext);
  const { theme } = useContext(PackageContext);
  const [messages, setMessages] = useState([]);
  const [countMessages, setCountMessages] = useState(0);

  useEffect(() => {
    const starterMessages = async () => {
      try {
        const response = await API(END.views.notifications);
        setMessages(response.data);
        setCountMessages(response.data.length);
      } catch (error) {
        console.error(error);
      }
    };

    starterMessages();

    const interval = setInterval(() => {
      starterMessages();
    }, 120000); // 300000 ms = 5 minutos

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const audio = new Audio('/oh.mp3');
    countMessages > 0 &&
      sounds === 1 &&
      audio.play().catch((err) => console.error(err));
    countMessages > 0 &&
      Toast({
        icon: 'info',
        title: 'Você tem novas notificações',
        position: 'top-end',
        timer: 3000,
      });
  }, [countMessages, sounds]);

  if (countMessages === 0) {
    return <IC.IoNotificationsSharp className='svgYellow svg14 mous' />;
  }

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          variant=''
          // size={size}
        >
          <div className='noty-icon'>
            {messages.length > 0 ? (
              <div
                className={`noty-num bgBlood ${
                  messages.length > 99 ? 'wp20 hp20' : ''
                }`}
              >
                {messages.length}
              </div>
            ) : null}
            <IC.IoNotificationsSharp className='svgYellow svg14' />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu
          data-bs-theme={theme}
          // size={size}
          // className='drop-menu-out'
          // id='drop-noty-menu'
        >
          {messages &&
            messages.map((item, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => {
                  if (item.TIPO === 'MENSAGEM') {
                    navigate('/mensagens');
                  } else {
                    navigate(`/processo/${item.PROCESSO_CODIGO}`);
                  }
                }}
              >
                <span className='spNavMenuIcon'>
                  {item.TIPO === 'MENSAGEM' ? (
                    <IC.LiaTelegramPlane className='svgCyan svg14 me-1' />
                  ) : item.ANDAMENTO === 'Simulação recebida' ? (
                    <IC.FcBullish className='svgYellow svg14 me-1' />
                  ) : (
                    <IC.RiMenuAddLine className='svgYellow svg14 me-1' />
                  )}

                  {item.TIPO === 'MENSAGEM' ? (
                    <span className='mx-1 textContrast fs10'>
                      Nova mensagem de{' '}
                      <span className='textContrastColor'>
                        {item.SENDER_NAME}
                      </span>
                    </span>
                  ) : (
                    <span className='mx-1 textContrast fs10'>
                      {item.ANDAMENTO === 'Simulação recebida'
                        ? `Nova simulação recebida de ${item.PROCESSO_NOME}`
                        : `Novo andamento no processo de ${item.PROCESSO_NOME}`}
                    </span>
                  )}
                </span>
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
