import React from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  CONF,
  ProtectedRoute,
  Login,
  Logout,
  Processo,
  ProcessoDocumento,
  ProcessoReport,
  SimulacaoReport,
  Public,
  Recover,
  Register,
  User,
} from '../config/imports';
import { Wrapper } from '../packages/wookie';
// import IconsExample from '../dump/IconsExample';
import Mensagens from '../pages/Mensagens';
// import Test from './pages/Test';

export default function AppRoutes() {
  return (
    <Routes>
      {/* <Route
        path='/icons'
        element={<IconsExample />}
      /> */}

      <Route
        path='/login'
        element={<Login />}
      />
      <Route
        path='/logout'
        element={<Logout />}
      />

      <Route
        path='/mensagens'
        element={
          <ProtectedRoute>
            <Mensagens />
          </ProtectedRoute>
        }
      />

      <Route
        path='/processo/:codigo'
        element={
          <ProtectedRoute>
            <Processo />
          </ProtectedRoute>
        }
      />

      <Route
        path='/documentos/:codigo'
        element={<ProcessoDocumento />}
      />

      <Route
        path='/documentos/:codigo/:mail/:phone'
        element={<ProcessoDocumento />}
      />

      <Route
        path='/report/processo/:codigo'
        element={
          <ProtectedRoute>
            <ProcessoReport />
          </ProtectedRoute>
        }
      />

      <Route
        path='/report/simulacao/:codigo'
        element={
          <ProtectedRoute>
            <SimulacaoReport />
          </ProtectedRoute>
        }
      />
      <Route
        path='/recover'
        element={<Recover />}
      />
      <Route
        path='/register'
        element={<Register />}
      />
      <Route
        path='/thanks'
        element={
          <Wrapper
            text='Recebemos suas informações e entraremos em contato em breve'
            clsnmText='fw500 fs16'
            textHeader='Obrigado!'
            conf={CONF}
          />
        }
      />
      <Route
        path='/user/:codigo'
        element={
          <ProtectedRoute>
            <User />
          </ProtectedRoute>
        }
      />

      <Route
        exact
        path='/'
        element={<Public />}
      />
      <Route
        exact
        path='/404'
        name='Page 404'
        element={
          <Wrapper
            text='Ooops! Página não encontrada'
            clsnmText='fw-500 fs-16'
            textHeader='Erro 404'
            conf={CONF}
          />
        }
      />
      <Route
        exact
        path='/500'
        name='Page 500'
        element={
          <Wrapper
            text='Acesso não permitido'
            clsnmText='fw-500 fs-16'
            textHeader='Erro 500'
            conf={CONF}
          />
        }
      />
      <Route
        path='*'
        element={
          <Wrapper
            text='Ooops! Página não encontrada'
            clsnmText='fw-500 fs-16'
            textHeader='Erro 404'
            conf={CONF}
          />
        }
      />
    </Routes>
  );
}
