import { useContext, useEffect, useState } from 'react';

import {
  API,
  END,
  CONF,
  GlobalContext,
  PKG,
  SimulacaoLine,
} from '../../../config/imports';

import Simulador from './simulador/Simulador';

export default function Simulacoes() {
  const { checkLogin } = useContext(GlobalContext);
  const { theme } = useContext(PKG.PackageContext);
  const [arraySimulacoes, setArraySimulacoes] = useState([]);
  const [filtered, setFiltered] = useState(arraySimulacoes);
  const [loading, setLoading] = useState(true);
  const [terms, setTerms] = useState('');
  const [update, setUpdate] = useState(false);

  const [simulacaoSection, setSimulacaoSection] = useState(true);

  // const [searchtxt, setSearchtxt] = useState('');

  useEffect(() => {
    const get_simulacoes = async () => {
      setLoading(true);
      try {
        const response = await API(END.sim.read.all, {});
        const data = response.data;
        if (data.success === false) {
          console.log('Erro ao carregar simulações');
        } else {
          const ordered = data.sort(
            (a, b) => new Date(b.CREATED_AT) - new Date(a.CREATED_AT),
          );
          setArraySimulacoes(ordered);
          setFiltered(ordered);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    checkLogin();
    get_simulacoes();
  }, [checkLogin, update]);

  const handleInputChange = (event) => {
    setTerms(event.target.value);
  };

  const handleUpdate = () => {
    setUpdate(!update);
    setSimulacaoSection(true);
  };

  // const handleSearchDatabase = async () => {
  //   try {
  //     const response = await API(END.sim.find, {
  //       searchtxt,
  //       // inativos,
  //     });
  //     const data = response.data;
  //     if (data.success === false) {
  //       console.log('Erro ao carregar simulações');
  //     } else {
  //       setArraySimulacoes(data);
  //       setFiltered(data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (terms.length > 2) {
      const filtered_array = arraySimulacoes.filter(
        ({ NOME, CPF, EMAIL, WHATSAPP, IMOVEL_CIDADE, IMOVEL_DESCRICAO }) =>
          (NOME && NOME.toLowerCase().includes(terms.toLowerCase())) ||
          (CPF && CPF.includes(terms)) ||
          (EMAIL && EMAIL.toLowerCase().includes(terms.toLowerCase())) ||
          (WHATSAPP && WHATSAPP.includes(terms)) ||
          (IMOVEL_CIDADE &&
            IMOVEL_CIDADE.toLowerCase().includes(terms.toLowerCase())) ||
          (IMOVEL_DESCRICAO &&
            IMOVEL_DESCRICAO.toLowerCase().includes(terms.toLowerCase())),
      );
      setFiltered(filtered_array);
    } else {
      setFiltered(arraySimulacoes);
    }
  }, [terms, arraySimulacoes]);

  const listing = () => {
    return (
      <>
        <div className='mb-4 gorow w100'>
          <div className='mt-2 gocol w100'>
            <label
              htmlFor='filter-sim'
              className='ffSoft fs10 mx-1'
            >
              Filtrar por nome, cpf, email, telefone, cidade ou operação
            </label>
            <input
              type='text'
              id='filter-sim'
              name='filter-sim'
              className='form-control w100'
              onChange={handleInputChange}
              pattern='.{3,20}'
            />
          </div>
        </div>

        {filtered.length > 0 ? (
          <div className=''>
            {/* <Table
              responsive
              hover
              className={`mb-3`}
              variant={theme}
            >
              <tbody> */}
                {filtered &&
                  filtered.map((sim) => (
                    <SimulacaoLine
                      key={sim.PROCESSO_CODIGO}
                      obj={sim}
                      upd={handleUpdate}
                    />
                  ))}
              {/* </tbody>
            </Table> */}
          </div>
        ) : (
          <p className='fs10 ffSoft'>Nenhuma simulação encontrada</p>
        )}
      </>
    );
  };

  if (loading)
    return (
      <>
        <PKG.Loading />
      </>
    );

  return (
    <div className='container mt-5'>
      <div className='d-flex flex-row align-items-center justify-content-between cBlue '>
        <div>
          <span className='fs20 ffSoft textContrastColor '>Simulações</span>
          <PKG.IconsUI
            info={PKG.ui.action.update}
            clsnm='svg14 ms-2 cLink hovGreen'
            click={handleUpdate}
            tooltip='Atualizar lista de simulações'
          />
        </div>
        <PKG.IconsUI
          info={PKG.ui.action.add.circle}
          clsnm='svg14 ms-2 cLink hovGreen mousePointerHover'
          click={() => setSimulacaoSection(!simulacaoSection)}
          sideText={ simulacaoSection ?  'Nova simulação' : 'Fechar'}
          sideTextClass='ms-2 fs10 ffSoft cLink mousePointerHover'
        />
      </div>

      {simulacaoSection ? listing() : 
      <Simulador
        useValidation={false}
        upd={handleUpdate}
        theme={theme}
        brand={CONF.brand}
        images={CONF.images}
        useBlueBg={false}
      />}
    </div>
  );
}
